<template>
  <main class="sub">
    <div class="inner">
      <div class="tit req-type">
        <h1>스마트 매칭</h1>
        <p class="stit">
          일자리 선호 조건을 기반으로 회원님께 적합한 일자리를 매칭해 드립니다.
        </p>
      </div>
      <div class="sub-cont">
        <div class="mypage-wrap">
          <div class="input-form multi">
            <div class="t-head">희망 근무지역</div>
            <div class="t-doc">
              <p class="check-box">
                <input
                  type="checkbox"
                  class="checkbox"
                  v-model="addrAllYn"
                  ref="addrAllYn"
                  true-value="Y"
                  false-value="N"
                  id="c1"
                />
                <label for="c1">지역 무관</label>
              </p>
              <div class="select-btn-list address-btn-list">
                <button
                  class="btn address-btn"
                  :class="{ on: isAddr(localItem) }"
                  type="button"
                  v-for="(localItem, idx) in localList"
                  :key="`li_${idx}`"
                  @click="selectaddrType(localItem)"
                >
                  {{ localItem.name }}
                </button>
              </div>
            </div>
          </div>
          <div class="input-form multi">
            <div class="t-head">희망 숙박업 유형</div>
            <div class="t-doc">
              <p class="check-box">
                <input
                  type="checkbox"
                  class="checkbox"
                  v-model="businessTypeAllYn"
                  ref="businessTypeAllYn"
                  true-value="Y"
                  false-value="N"
                />
                <label for="c2">전체</label>
              </p>
              <div class="select-btn-list">
                <button
                  class="btn"
                  :class="{ on: isActive(btElement) }"
                  type="button"
                  v-for="(btElement, idx) in businessTypeCodeList"
                  :key="`bt_${idx}`"
                  @click="selectBusinessType(btElement)"
                >
                  {{ btElement.codeValue }}
                </button>
              </div>
            </div>
          </div>
          <div class="input-form multi">
            <div class="t-head">희망 구인직종</div>
            <div class="t-doc">
              <span class="help_msg">
                근무를 희망하시는 직종과 세부직종을 선택하신 후
                <span>추가</span>버튼을 누르세요.
              </span>
              <div class="select-btn">
                <select
                  class="select"
                  name=""
                  id=""
                  ref="parentPositionId"
                  v-model="parentPositionId"
                >
                  <option value="NONE">직종</option>
                  <option
                    v-for="(jobPosition, idx) in jobPositionList"
                    :key="`jp_${idx}`"
                    :value="jobPosition.jobPositionId"
                  >
                    {{ `${jobPosition.positionName}` }}
                  </option>
                </select>
                <select
                  class="select"
                  name=""
                  ref="positionId"
                  id=""
                  v-model="positionId"
                >
                  <option value="NONE">세부직종</option>
                  <option
                    v-for="(jobPositionDetail, idx) in jobDetailPositionList"
                    :key="`jp_${idx}`"
                    :value="jobPositionDetail.jobPositionDtlId"
                  >
                    {{ `${jobPositionDetail.positionDtlName}` }}
                  </option>
                </select>
                <button
                  ref="selectPosition"
                  class="btn __select"
                  @click="selectPosition"
                >
                  추가
                </button>
              </div>
              <ul class="select-list">
                <li
                  v-for="(jobPosition, idx) in selectedJobPositions"
                  :key="`sp_${idx}`"
                >
                  <span>
                    {{
                      `${jobPosition.jobPositionName} ${jobPosition.jobPositionDetailName}`
                    }}
                  </span>
                  <button class="btn __delete" @click="deletePosition(idx)">
                    <i class="el-icon-close"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="input-form">
            <div class="t-head">희망 일급</div>
            <div class="t-doc">
              <div class="pay-box">
                <input
                  type="text"
                  class="text"
                  v-model="payAmtMin"
                  ref="payAmtMin"
                  @keyup="formatCurrencyMin"
                />
                <span>-</span>
                <input
                  type="text"
                  class="text"
                  v-model="payAmtMax"
                  ref="payAmtMax"
                  @keyup="formatCurrencyMax"
                />
              </div>
            </div>
          </div>
          <div class="input-form multi">
            <div class="t-head">근무가능요일</div>
            <div class="t-doc">
              <ul class="check-list">
                <li>
                  <input
                    type="checkbox"
                    class="checkbox"
                    id="c1-1"
                    v-model="availAllDayYn"
                    ref="availAllDayYn"
                    true-value="Y"
                    false-value="N"
                  />
                  <label for="c1-1">요일 무관</label>
                </li>
                <li
                  v-show="
                    availAllDayYn === 'N' ||
                    availAllDayYn === undefined ||
                    availAllDayYn === ''
                  "
                >
                  <input
                    type="checkbox"
                    class="checkbox"
                    id="c1-2"
                    v-model="availSunYn"
                    true-value="Y"
                    false-value="N"
                  />
                  <label for="c1-2">일</label>
                </li>
                <li
                  v-show="
                    availAllDayYn === 'N' ||
                    availAllDayYn === undefined ||
                    availAllDayYn === ''
                  "
                >
                  <input
                    type="checkbox"
                    class="checkbox"
                    id="c1-3"
                    v-model="availMonYn"
                    true-value="Y"
                    false-value="N"
                  />
                  <label for="c1-3">월</label>
                </li>
                <li
                  v-show="
                    availAllDayYn === 'N' ||
                    availAllDayYn === undefined ||
                    availAllDayYn === ''
                  "
                >
                  <input
                    type="checkbox"
                    class="checkbox"
                    id="c1-4"
                    v-model="availTueYn"
                    true-value="Y"
                    false-value="N"
                  />
                  <label for="c1-4">화</label>
                </li>
                <li
                  v-show="
                    availAllDayYn === 'N' ||
                    availAllDayYn === undefined ||
                    availAllDayYn === ''
                  "
                >
                  <input
                    type="checkbox"
                    class="checkbox"
                    id="c1-5"
                    v-model="availWedYn"
                    true-value="Y"
                    false-value="N"
                  />
                  <label for="c1-5">수</label>
                </li>
                <li
                  v-show="
                    availAllDayYn === 'N' ||
                    availAllDayYn === undefined ||
                    availAllDayYn === ''
                  "
                >
                  <input
                    type="checkbox"
                    class="checkbox"
                    id="c1-6"
                    v-model="availThrYn"
                    true-value="Y"
                    false-value="N"
                  />
                  <label for="c1-6">목</label>
                </li>
                <li
                  v-show="
                    availAllDayYn === 'N' ||
                    availAllDayYn === undefined ||
                    availAllDayYn === ''
                  "
                >
                  <input
                    type="checkbox"
                    class="checkbox"
                    id="c1-7"
                    v-model="availFriYn"
                    true-value="Y"
                    false-value="N"
                  />
                  <label for="c1-7">금</label>
                </li>
                <li
                  v-show="
                    availAllDayYn === 'N' ||
                    availAllDayYn === undefined ||
                    availAllDayYn === ''
                  "
                >
                  <input
                    type="checkbox"
                    class="checkbox"
                    id="c1-8"
                    v-model="availSatYn"
                    true-value="Y"
                    false-value="N"
                  />
                  <label for="c1-8">토</label>
                </li>
              </ul>
            </div>
          </div>
          <!-- 근무가능시간 30분단위로 세팅하도록 수정 -->
          <div class="input-form">
            <div class="t-head">근무가능시간</div>
            <div class="t-doc">
              <div class="select-btn">
                <select
                  v-model="availWorkingStartTime"
                  ref="availWorkingStartTime"
                  name=""
                  id=""
                  class="select"
                >
                  <option
                    v-for="(workTime, idx) in WORK_TIME_LIST"
                    :key="`to_${idx}`"
                    :value="workTime"
                    :selected="availWorkingStartTime === workTime"
                  >
                    {{ workTime }}
                  </option>
                </select>
                <select
                  class="select"
                  ref="availWorkingEndTime"
                  v-model="availWorkingEndTime"
                  name=""
                  id=""
                >
                  <option
                    v-for="(workTime, idx) in WORK_TIME_LIST"
                    :key="`to_${idx}`"
                    :value="workTime"
                    :selected="availWorkingEndTime === workTime"
                  >
                    {{ workTime }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="myp-btn-wrap">
      <button type="button" class="btn-save01" @click="save">저장</button>
    </div>
  </main>
</template>

<script>
import {
  getBusinesTypeList,
  getJobPositionList,
  getJobPositionDetailList,
  getSmartMatchingInfo,
  saveSmartMatchingInfo,
} from 'Api/modules'
import { mapGetters, mapMutations } from 'vuex'
//import { searchAddressGetResult } from 'Utils/message'
import { WORK_TIME } from 'Configs/registJob'
import { LOCAL_LIST_TWO } from 'Configs/filter.js'
export default {
  data() {
    return {
      localList: JSON.parse(JSON.stringify(LOCAL_LIST_TWO)),
      WORK_TIME_LIST: Object.freeze(WORK_TIME),
      userId: '',
      addrAllYn: '',
      localItem: '',
      addressList: [],
      region1Depths: '',
      region2Depths: '',
      businessTypeAllYn: '',
      addressTypeAllYn: '',
      businessTypeList: [],
      businessTypeCodeList: [],
      // businessTypeCodes: '',
      businessTypeNames: '',
      parentPositionId: 'NONE',
      positionId: 'NONE', //세부직종 아이디
      jobPositionList: [], //{jobPositionId, positionName, jobCategory, jobCategoryName, payAmt, registDt}
      jobDetailPositionList: [], //{jobPositionId, positionName, jobCategory, jobCategoryName, payAmt, registDt}
      currPositionId: '',
      currPositionName: '',
      currPositionDetailId: '',
      currPositionDetailName: '',
      jobPositionIds: '',
      jobPositionDtlIds: '',
      jobPositionNames: '',
      jobPositionDtlNames: '',
      selectedJobPositions: [],
      payAmtMin: '0',
      payAmtMax: '0',
      availAllDayYn: '',
      availMonYn: '',
      availTueYn: '',
      availWedYn: '',
      availThrYn: '',
      availFriYn: '',
      availSatYn: '',
      availSunYn: '',
      availWorkingStartTime: '',
      availWorkingEndTime: '',
    }
  },
  watch: {
    //직종 선택
    async parentPositionId(positionId) {
      const jobPosition = this.jobPositionList.filter(
        x => x.jobPositionId === positionId,
      )
      this.currPositionId = positionId
      this.currPositionName =
        positionId == 'NONE' ? 'NONE' : jobPosition[0].positionName
      //세부 직종 목록 조회
      console.log(positionId)
      if (positionId) {
        if (positionId == 'NONE') {
          this.positionId = 'NONE'
          this.jobDetailPositionList = []
          this.currPositionDetailId = ''
          this.currPositionDetailName = ''
          return
        }
        const res = await getJobPositionDetailList({
          positionId,
        })
        this.positionId = 'NONE'
        this.jobDetailPositionList = res
        this.currPositionDetailId = ''
        this.currPositionDetailName = ''
      }
    },
    //세부직종 선택 : 직종에 따라 세부직종은 있을 수도, 없을 수도 있다.
    async positionId(positionId) {
      if (positionId && positionId != 'NONE') {
        const jobPosition = this.jobDetailPositionList.filter(
          x => x.jobPositionDtlId === positionId,
        )
        this.currPositionDetailId = positionId
        this.currPositionDetailName = jobPosition[0].positionDtlName
        //옵션 목록 조회
        // await this.fetchJobOptionList(positionId)
      }
    },
    // availWorkingStartTime(newVal) {
    availWorkingStartTime() {
      this.diffTime('startTime')
    },
    // availWorkingEndTime(newVal) {
    availWorkingEndTime() {
      this.diffTime('endTime')
    },
    // payAmtMin(newVal) {
    //   this.diffAmt('startAmt')
    // },
    // payAmtMax(newVal) {
    //   this.diffAmt('endAmt')
    // },
    // async addrAllYn(addrAllYn) {
    //   if (addrAllYn === 'Y') {
    //     this.addressList = []
    //   }
    // },
  },
  computed: {
    ...mapGetters(['account']),
  },
  methods: {
    ...mapMutations(['SM_MAT_INFO']),
    formatCurrencyMin() {
      this.payAmtMin = this.insertCommaToNumber(this.payAmtMin)
    },
    formatCurrencyMax() {
      this.payAmtMax = this.insertCommaToNumber(this.payAmtMax)
    },
    diffTime(flag) {
      const startTime = this.availWorkingStartTime
      const endTime = this.availWorkingEndTime
      if (startTime && endTime) {
        if (startTime > endTime) {
          if (flag === 'startTime') {
            this.$toasted.error('시작시간은 종료시간을 초과할 수 없습니다.')
            this.availWorkingStartTime = ''
          } else if (flag === 'endTime') {
            this.$toasted.error('종료시간은 시작시간 이전일 수 없습니다.')
            this.availWorkingEndTime = ''
          }
        }
      }
    },
    // diffAmt(flag) {
    //   const payAmtMin = this.payAmtMin
    //   const payAmtMax = this.payAmtMax
    //   if (payAmtMin && payAmtMax) {
    //     if (payAmtMin > payAmtMax) {
    //       if (flag === 'startAmt') {
    //         this.$toasted.error('최소금액이 최대금액을 초과할 수 없습니다.')
    //         this.payAmtMin = '0'
    //       } else if (flag === 'endAmt') {
    //         this.$toasted.error('최대금액이 최소금액보다 작을 수 없습니다.')
    //         this.payAmtMax = '0'
    //       }
    //     }
    //   }
    // },
    // findAddress() {
    //   if (this.isApp) {
    //     searchAddressGetResult(({ sido, sigungu, bname, address }) => {
    //       const found = this.localOnList.findIndex(
    //         item =>
    //           item.region1DepthName === sido &&
    //           item.region2DepthName === sigungu,
    //       )

    //       if (found > -1) {
    //         this.$toasted.show('이미 추가된 지역입니다.')
    //         return
    //       }

    //       this.localOnList.push({
    //         region1DepthName: sido,
    //         region2DepthName: sigungu,
    //         region3DepthName: bname,
    //         address: address,
    //       })
    //     })
    //   } else {
    //     //카카오 지도 발생
    //     new window.daum.Postcode({
    //       oncomplete: data => {
    //         //선택시 입력값 세팅
    //         const { sido, sigungu, bname, address } = data
    //         // console.error(sido, sigungu, bname, address)
    //         console.error(sido, sigungu)

    //         // let searched = false
    //         // this.addressList.forEach(e => {
    //         //   const targetRegion = e.region1DepthName + e.region2DepthName
    //         //   const sourceRegion = sido + sigungu
    //         //   if (targetRegion === sourceRegion) {
    //         //     searched = true
    //         //   }
    //         // })
    //         // if (!searched) {
    //         this.localOnList.push({
    //           region1DepthName: sido,
    //           region2DepthName: sigungu,
    //           region3DepthName: bname,
    //           address: address,
    //         })
    //         // }
    //       },
    //     }).open()
    //   }
    // },
    // deleteAddress(idx) {
    //   this.localOnList.splice(idx, 1)
    // },
    selectBusinessType(businessTypeElement) {
      let searched = false
      this.businessTypeList.forEach(e => {
        if (e.codeValue === businessTypeElement.codeValue) {
          this.businessTypeList = this.businessTypeList.filter(
            x => x.codeValue !== businessTypeElement.codeValue,
          )
          searched = true
        }
      })
      // 동일한 엘리먼트가 없으면 활성화시켜 추가한다.
      if (!searched) {
        // businessTypeElement.selected = !businessTypeElement.selected
        this.businessTypeList.push(businessTypeElement)
      }
      console.log(this.businessTypeNames)
      console.log(this.businessTypeCodeList)
    },
    selectaddrType(localItem) {
      let searched = false
      this.addressList.forEach(e => {
        if (e.name === localItem.name) {
          this.addressList = this.addressList.filter(
            x => x.name !== localItem.name,
          )
          searched = true
        }
      })
      // 동일한 엘리먼트가 없으면 활성화시켜 추가한다.
      if (!searched) {
        // businessTypeElement.selected = !businessTypeElement.selected
        this.addressList.push(localItem)
      }
    },
    isActive(businessTypeElement) {
      let active = false
      this.businessTypeList.forEach(e => {
        if (e.codeValue === businessTypeElement.codeValue) active = true
      })
      return active || (this.businessTypeAllYn && this.businessTypeAllYn == 'Y')
    },
    isAddr(localItem) {
      let active = false
      this.addressList.forEach(e => {
        if (e.name === localItem.name) active = true
      })
      return active || (this.addrAllYn && this.addrAllYn == 'Y')
    },
    //직종 선택
    selectPosition() {
      // 중복처리
      let searched = false
      this.selectedJobPositions.forEach(position => {
        const entryPositionName =
          position.jobPositionName + ' ' + position.jobPositionDetailName
        const curPositionName =
          this.currPositionName + ' ' + this.currPositionDetailName
        if (entryPositionName == curPositionName) searched = true
      })
      if (
        searched ||
        !this.currPositionId ||
        !this.currPositionDetailId ||
        this.currPositionId == 'NONE' ||
        this.currPositionDetailId == 'NONE'
      )
        return
      this.selectedJobPositions.push({
        jobPositionId: this.currPositionId,
        jobPositionDetailId: this.currPositionDetailId,
        jobPositionName: this.currPositionName,
        jobPositionDetailName: this.currPositionDetailName,
      })
    },
    deletePosition(idx) {
      this.selectedJobPositions.splice(idx, 1)
    },
    validateInput(data) {
      let ok = false
      for (let value of Object.values(data)) {
        if (value !== '' && value !== null && value !== '0') {
          ok = true
          break
        }
      }
      return ok
    },

    async save() {
      // 복수지역 선택시 콤마(,)를 붙여서 문자열로 만들어줘야 한다.
      this.region1Depths = ''
      //this.region2Depths = ''
      let count = 0
      this.addressList.forEach(e => {
        if (count > 0) {
          this.region1Depths += ','
          //this.region2Depths += ','
        }
        this.region1Depths += e.name
        //this.region2Depths += e.region2DepthName
        count++
      })

      // 복수 숙박업 유형을 선택시 콤마(,)를 붙여서 문자열로 만들어줘야 한다.
      // this.businessTypeCodes = ''
      this.businessTypeNames = ''
      count = 0
      this.businessTypeList.forEach(e => {
        if (count > 0) {
          // this.businessTypeCodes += ','
          this.businessTypeNames += ','
        }
        this.businessTypeNames += e.codeValue
        // this.businessTypeCodes += e.id.dtlCd
        count++
      })

      // 복수 희망지역 유형을 선택시 콤마(,)를 붙여서 문자열로 만들어줘야 한다.
      // this.businessTypeCodes = ''
      // this.localItem = ''
      // count = 0
      // this.localOnList.forEach(e => {
      //   if (count > 0) {
      //     // this.businessTypeCodes += ','
      //     this.localItem += ','
      //   }
      //   this.localItem += e.name
      //   // this.businessTypeCodes += e.id.dtlCd
      //   count++
      // })

      // 복수개의 직종을 선택시 콤마(,)를 붙여서 문자열로 만들어줘야 한다.
      this.jobPositionIds = ''
      this.jobPositionDtlIds = ''
      this.jobPositionNames = ''
      this.jobPositionDtlNames = ''
      count = 0
      this.selectedJobPositions.forEach(e => {
        if (count > 0) {
          this.jobPositionIds += ','
          this.jobPositionDtlIds += ','
          if (this.jobPositionNames) this.jobPositionNames += ','
          if (this.jobPositionDtlNames) this.jobPositionDtlNames += ','
        }
        this.jobPositionNames +=
          e.jobPositionName + '-' + e.jobPositionDetailName
        count++
      })

      // const availDayArry = [
      //   this.availSunYn,
      //   this.availMonYn,
      //   this.availTueYn,
      //   this.availWedYn,
      //   this.availThrYn,
      //   this.availFriYn,
      //   this.availSatYn,
      // ]

      // const availDay = availDayArry.find(local => local === 'Y')

      //희망 근무지역
      if (
        (!this.addrAllYn && this.addressList.length === 0) ||
        (this.addrAllYn === 'N' && this.addressList.length === 0)
      ) {
        this.$toasted.error('희망 근무지역을 선택해주세요.')
        this.$refs['addrAllYn'].focus()
        return
      }
      //희망 숙박업 유형
      // if (
      //   (!this.businessTypeAllYn && this.businessTypeList.length === 0) ||
      //   (this.businessTypeAllYn === 'N' && this.businessTypeList.length === 0)
      // ) {
      //   this.$toasted.error('희망 숙박업 유형을 선택해주세요.')
      //   this.$refs['businessTypeAllYn'].focus()
      //   return
      // }
      //희망 구인직종
      // if (!this.currPositionName && this.selectedJobPositions.length === 0) {
      //   this.$toasted.error('희망 구인직종을 선택해주세요.')
      //   this.$refs['parentPositionId'].focus()
      //   return
      // }
      // 희망 세부 구인직종
      // if (
      //   !this.currPositionDetailName &&
      //   this.selectedJobPositions.length === 0
      // ) {
      //   this.$toasted.error('희망 세부 구인직종을 선택해주세요.')
      //   this.$refs['positionId'].focus()
      //   return
      // }
      // if (this.selectedJobPositions.length === 0) {
      //   this.$toasted.error('희망 구인직종을 추가해주세요.')
      //   this.$refs['selectPosition'].focus()
      //   return
      // }
      if (
        Number(this.payAmtMin.split(',').join('')) >
        Number(this.payAmtMax.split(',').join(''))
      ) {
        this.$toasted.error(
          '희망하시는 최소 일급이 최대 일급을 초과할 수 없습니다.',
        )
        this.$refs['payAmtMin'].focus()
        return
      }
      if (Number(this.payAmtMax.split(',').join('')) > 300000) {
        this.$toasted.error(
          '희망하시는 최대 일급이 300,000원을 초과할 수 없습니다.',
        )
        this.$refs['payAmtMin'].focus()
        return
      }
      //근무가능요일
      // if (
      //   (!this.availAllDayYn && availDay !== 'Y') ||
      //   (this.availAllDayYn === 'N' && availDay !== 'Y')
      // ) {
      //   this.$toasted.error('근무가능요일을 선택해주세요.')
      //   this.$refs['availAllDayYn'].focus()
      //   return
      // }
      const saveData = {
        userId: this.userId,
        addressAllYn: this.addrAllYn,
        region1Depths: this.region1Depths,
        //region2Depths: this.region2Depths,
        businessTypeAllYn: this.businessTypeAllYn,
        businessTypeCodes: this.businessTypeCodes,
        businessTypeNames: this.businessTypeNames,
        jobPositionNames: this.jobPositionNames,
        payAmtMin: this.payAmtMin.split(',').join(''), //스트링으로 전송
        payAmtMax: this.payAmtMax.split(',').join(''), //스트링으로 전송
        availDayAllYn: this.availAllDayYn,
        availMonYn: this.availMonYn,
        availTueYn: this.availTueYn,
        availWedYn: this.availWedYn,
        availThrYn: this.availThrYn,
        availFriYn: this.availFriYn,
        availSatYn: this.availSatYn,
        availSunYn: this.availSunYn,
        availWorkingStartTime: this.availWorkingStartTime,
        availWorkingEndTime: this.availWorkingEndTime,
      }
      //입력 오류
      if (!this.validateInput(saveData)) {
        this.$toasted.error('저장할 정보를 입력해주세요.')
        return false
      }

      try {
        await this.SM_MAT_INFO()
        await saveSmartMatchingInfo(saveData)
        this.$toasted.show('스마트매칭 정보가 등록되었습니다.')
        setTimeout(() => {
          this.$router.go() // 새로고침
        }, 300)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
  },
  async created() {
    // 사용자 아이디
    const userId = await this.account.userId
    //숙박업 유형 목록 조회
    this.businessTypeCodeList = await getBusinesTypeList()

    //직종 목록 조회
    const res = await getJobPositionList()
    this.jobPositionList = res

    const smartMatching = await getSmartMatchingInfo({ userId })
    if (smartMatching) {
      // 직종 그리기
      const jobPositionNames = smartMatching.jobPositionNames
      const namesArr = jobPositionNames?.length
        ? jobPositionNames.split(',')
        : []
      for (let i = 0; i < namesArr.length; i++) {
        const nameArr = namesArr[i].split('-')
        this.selectedJobPositions.push({
          jobPositionName: nameArr[0],
          jobPositionDetailName: nameArr[1],
        })
      }

      // 숙박업 매칭 그리기
      // businessTypeList
      this.businessTypeNames = smartMatching.businessTypeNames
      const bizNameArr = this.businessTypeNames?.length
        ? this.businessTypeNames.split(',')
        : []
      for (let i = 0; i < bizNameArr.length; i++) {
        this.businessTypeList.push({
          codeValue: bizNameArr[i],
        })
      }
      // 근무지역 그리기
      this.region1Depths = smartMatching.region1Depths
      //this.region2Depths = smartMatching.region2Depths
      const region1Arr = this.region1Depths?.length
        ? this.region1Depths.split(',')
        : []
      for (let i = 0; i < region1Arr.length; i++) {
        this.addressList.push({
          name: region1Arr[i],
        })
      }
      // const region

      // 기타 설정
      this.addrAllYn = smartMatching.addressAllYn
      this.businessTypeAllYn = smartMatching.businessTypeAllYn
      this.payAmtMin = this.insertCommaToNumber(smartMatching.payAmtMin)
      this.payAmtMax = this.insertCommaToNumber(smartMatching.payAmtMax)
      this.availAllDayYn = smartMatching.availAllDayYn
      this.availMonYn = smartMatching.availMonYn
      this.availTueYn = smartMatching.availTueYn
      this.availWedYn = smartMatching.availWedYn
      this.availThrYn = smartMatching.availThrYn
      this.availFriYn = smartMatching.availFriYn
      this.availSatYn = smartMatching.availSatYn
      this.availSunYn = smartMatching.availSunYn
      this.availWorkingStartTime = smartMatching.availWorkingStartTime.slice(
        0,
        -3,
      )
      this.availWorkingEndTime = smartMatching.availWorkingEndTime.slice(0, -3)
    }
  },
}
</script>

<style scoped>
main.sub .tit.m-tit {
  z-index: 1;
}
.help_msg {
  margin-bottom: 5px;
}
.help_msg > span {
  font-weight: bold;
}
.select-list {
  flex-wrap: wrap;
}
</style>
